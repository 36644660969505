export const Index_item = [
    {
      id: 1,
      compName: '互勾聊天',
      title: '互勾·聊天',
      conetent: [
        {
          type: 'title',
          descript: '详细介绍'
        },
        {
          type: 'small-title',
          descript: '多种实用功能于一体，是你便携生活的得力助手'
        },
        {
          type: 'news',
          descript: '【业主管理】：轻松管理家属、访客名单，控制出入权限。'
        },
        {
          type: 'news',
          descript: ' 【钥 匙 包】：多种开门方式，满足不同场景下的开门需求。'
        },
        {
          type: 'news',
          descript: '【小区列表】：可同时加入多个小区'
        }
      ],
      slogan: '打破一切沟通障碍，万事互联。',
      qrcodeImg: 'qrcode_im.png',
      iOSUrl: 'https://testflight.apple.com/join/jbPyC46n',
      androidUrl: 'https://app.tikfriend.com/down/hugou_im.apk',
      androidUrl32: 'https://app.tikfriend.com/down/hugou_im_32.apk ',
      mainImgTitle: '_chat'
    },
    {
      id: 2,
      compName: '互勾办公',
      title: '互勾·办公',
      conetent: [
        {
          type: 'title',
          descript: '详细介绍'
        },
        {
          type: 'small-title',
          descript: '致力于提高老板的管理效率，是你便捷生活、高效办公的得力助手。'
        },
        {
          type: 'news',
          descript: '【账号列表】：多种账号轻松管理'
        },
        {
          type: 'news',
          descript: '【机构通讯录】：按组织架构查看机构成员信息，获取部门主管、部门人员岗位、人员联系方式。'
        },
        {
          type: 'news',
          descript: '【小区物业】：一键管理门禁设备、业主住户'
        }
      ],
      slogan: '你的机构，由你掌控',
      qrcodeImg: 'qrcode_work.png',
      iOSUrl: 'https://apps.apple.com/cn/app/id1529573416',
      androidUrl: 'https://app.tikfriend.com/down/hugou_work.apk',
      // windowsUrl: 'https://app.tikfriend.com/down/hugou_work_windows.exe',
      androidUrl32: 'https://app.tikfriend.com/down/hugou_work_32.apk',
      mainImgTitle: '_work'
    },
    {
      id: 3,
      compName: '互勾监控',
      title: '互勾·监控',
      conetent: [
        {
          type: 'title',
          descript: '详细介绍'
        },
        // {
        //   type: 'small-title',
        //   descript: '【关联设备】：关联主流设备，视界无疆。'
        // },
        {
          type: 'news',
          descript: '【关联设备】：关联主流设备，视界无疆。'
        },
        {
          type: 'news',
          descript: '【视频回放】：智能视频回放，不疏不漏。'
        },
        {
          type: 'news',
          descript: '【视频分享】：共享视频查看，报达平安。'
        }
      ],
      slogan: '让视界就在你眼前',
      qrcodeImg: 'qrcode_monitor.png',
      iOSUrl: '',
      androidUrl: 'https://app.tikfriend.com/down/hugou_monitor_32.apk',
      // windowsUrl: 'https://app.tikfriend.com/down/hugou_work_windows.exe',
      androidUrl32: 'https://app.tikfriend.com/down/hugou_monitor.apk',
      mainImgTitle: '_monitor'
    },
    // {
    //   id: 3,
    //   compName: '互勾动态',
    //   title: '互勾·动态',
    //   conetent: [
    //     {
    //       title: '',
    //       descript: '多个不同模块自由组合形成个性化动态，记录你多彩的生活。'
    //     },
    //     {
    //       title: '1、',
    //       descript: '支持选择模版发布不同类型的动态。'
    //     },
    //     {
    //       title: '2、',
    //       descript: '支持多个不同模块自由组合形成个性化动态，记录你多彩的生活。'
    //     },
    //     {
    //       title: '3、',
    //       descript: '支持发起各类活动，聚会派对等，让你的生活更加丰富。'
    //     },
    //     {
    //       title: '4、',
    //       descript: '支持上传照片墙，制作专属于你的独家记忆。'
    //     },
    //     {
    //       title: '5、',
    //       descript: '全世界/好友发布、分享的动态，看看不同的世界。'
    //     },
    //     {
    //       title: '6、',
    //       descript: '全支持有声朗读，听见快乐的时光'
    //     },
    //   ],
    //   slogan: '活在当下，及时行乐',
    //   qrcodeImg: 'qrcode_comment.png',
    //   iOSUrl: 'https://apps.apple.com/cn/app/id1526259973',
    //   androidUrl: 'https://app.tikfriend.com/down/hugou_comment.apk'
    // },
    // {
    //   id: 4,
    //   compName: '互勾收藏',
    //   title: '互勾·收藏',
    //   conetent: [
    //     {
    //       title: '',
    //       descript: '互勾收藏是由砾石公司出品的一款个人云服务产品，使用互勾收藏，领取超大空间，相册、文件、视频、音频全部收藏，注册就送10G免费容量。'
    //     },
    //     {
    //       title: '「传输功能」：',
    //       descript: '上传下载速度飞快。支持断点续传。超大文件上传下载。'
    //     },
    //     {
    //       title: '「私密空间」：',
    //       descript: '全不同密码，不同私密空间，守护你心中的小秘密。'
    //     },
    //     {
    //       title: '「分享功能」：',
    //       descript: '世间美好，值得分享，分享出你的精彩。'
    //     },
    //   ],
    //   slogan: '世间美好，尽在收藏',
    //   qrcodeImg: 'qrcode_goudrive.png',
    //   iOSUrl: 'https://apps.apple.com/cn/app/id1530123036',
    //   androidUrl: 'https://app.tikfriend.com/down/hugou_goudrive.apk'
    // }
  ]
